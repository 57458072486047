export const getPosts = (data) => {
       return fetch(`${process.env.REACT_APP_BACKEND_URL}/posts`,{
              method: "POST",
              headers: {
                     "content-type": "application/json",
                     "Accept": "application/json"
              },
              body: JSON.stringify(data)
       })
       .then(response => response.json())
       .catch(err => console.log(err))
}

export const getMorePosts = (data) => {
       return fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/more`,{
              method: "POST",
              headers: {
                     "content-type": "application/json",
                     "Accept": "application/json"
              },
              body: JSON.stringify(data)
       })
       .then(response => response.json())
       .catch(err => console.log(err))
}